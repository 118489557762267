import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="container">
      <h1 className="mt-5 text-primary display-4 mb-5">Privacy Policy</h1>
      <p className="fs-5">
        This privacy policy will explain what information we collect online, why we collect it, and the choices we offer
        to you. This privacy policy (“Policy”) describes the data practices of CrossJump Studio (“CrossJump Studio”) and
        its subsidiaries (collectively, the “we”, or “Company”), covering its services under its control, including this
        website, any products, mobile applications, console or PC applications (collectively "Services"). By using the
        Services, you are consenting to this Policy and the processing of your data in the manner provided in this
        Policy. If you do not agree with these terms, please do not use the Services. This Privacy Policy was last
        updated on September 9th, 2022.
      </p>

      <h3 className="mt-5 text-primary">Your Consent</h3>

      <p className="fs-5">
        By downloading and playing our games, you agree to the terms of this Privacy Policy and you particularly consent
        with the collection, use and disclosure of your information. You are always free to opt out of the information
        collection by not using our mobile games or services.
      </p>

      <h3 className="mt-5 text-primary">Kids Privacy Policy</h3>

      <p className="fa-5">
        We have to protect children’s privacy and safety online which contains fulfilling with limits on marketing to
        those users under 13. Without their legal guardians’ consent, children under 13 cannot use any of our
        games/apps. In addition, they may not install, use or access these apps, or are intreated to expunge them from
        their device in case of earlier installation if they or their legal guardians disagree with any term in this
        Privacy Policy.
      </p>

      <p className="fs-5">
        Parents & legal guardians of children under 13 have certain rights under Children’s Online Privacy Protection
        Act of 1998 (COPPA, http://www.coppa.org/coppa.htm), and CrossJump Studio recognizes those rights.{" "}
      </p>

      <p className="fs-5">
        Our Kids Games contain third parties SDKs that gets non-personal information and Kids’ Determined Device
        Identifier through the use of the Kids Games/Apps. Both, non-personal information and Kids’ Persistent Device
        Identifier may also be kept on the user’s device through usually putative industry technologies used within Kids
        Apps (cookies, local storage).
      </p>

      <h3 className="mt-5 text-primary">Information We Collect</h3>

      <p className="fs-5">
        We may collect information about the Services you use and how you use them, such as the selections you make on
        our Services. We collect PII, DII, and log information about your interactions as described below.
      </p>

      <p className="fs-5">
        Personally identifiable information (PII) is information that can be used to identify or contact you online or
        offline, such as your name, address, email, phone number, photos or audio data, and payment information. The
        Service may collect PII when it is provided to us, such as when you use our Services, attempt to contact us,
        submit a resume or job application, or connect with us on social media or one of our partners. For example, you
        may see a “Log in with…” button, which means we request PII from a partner to streamline the login the process.
        You will likely be presented with a “request for permission” screen by a third party asking to share your ID,
        profile picture, and other listed information with us.
      </p>

      <p className="fs-5">
        Our Services also collect information about your interactions, including navigation paths, search queries,
        crashes, timestamps, purchases, clicks and shares, and referral URLs. We may combine this data with PII and DII.
        For efficiency’s sake, information about your interactions may be transmitted to our servers while you are not
        using the app. We may also partner with third parties that collect additional information – please see their
        privacy policies for more details and see below for your choices regarding these parties.
      </p>

      <p className="fs-5">
        We may also create or collect device-identifiable information (DII), such as cookies, unique device and
        advertising identifiers, statistical identifiers, usernames, and similar identifiers that are linkable to a
        browser or device. From these platforms, we may also receive other information, such as your IP address, user
        agent, timestamps, precise and imprecise geolocation, sensor data, apps, fonts, battery life information, and
        screen size.
      </p>

      <h3 className="mt-5 text-primary">How We Use Information Which We Collect</h3>

      <p className="fs-5">
        We use the information we collect from our Services to provide, maintain, protect and improve our Services, to
        develop new Services and offerings, and to protect us and our users. PII is primarily used for business
        purposes, such as for sending you occasional newsletters and updates, hiring, responding to inquiries, logins,
        and providing Services.
      </p>

      <p className="fs-5">
        For legal reasons - We will share PII with companies, outside organizations or individuals if we have a
        good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to
        meet any applicable law, regulation, legal process or enforceable governmental request, detect, prevent, or
        otherwise address fraud, security or technical issues or protect against harm to the rights, property or safety
        of our users or the public as required or permitted by law.
      </p>

      <p className="fs-5">
        When you contact us, we may keep a record of your communication as well as the other information to help solve
        any issues you might be facing. We may use your email address to inform you about our Services, such as letting
        you know about changes or improvements. Please keep in mind that comments sections, forums, and other similar
        areas of our Services are public. Any information posted in those areas is viewable and usable by anyone that
        has access.
      </p>

      <p className="fs-5">
        For external processing - We provide PII to our affiliates or other trusted businesses or persons to process it
        for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate
        confidentiality and security measures.
      </p>

      <p className="fs-5">
        In case of a sale or asset transfer - If we become involved in a merger, acquisition or other transaction
        involving the sale of some or all of our assets, user information, including PII collected from you through your
        use of our Services, could be included in the transferred assets. Should such an event occur, we will use
        reasonable means to notify you, either through email and/or a prominent notice on the Services.
      </p>

      <h3 className="mt-5 text-primary">Sharing Information</h3>
      <p className="fs-5">
        We will share your (personal) information with third parties only in the ways that are described in this privacy
        statement. We may disclose your personal information only in the following cases:
      </p>

      <p className="fs-5">
        CrossJump Studio admits advertisements (banners, interstitials.) from third parties ad networks. These
        advertisements may be displayed in our games/apps. Advertisers may use cookies and other web-tracking latest
        technologies to collect data in case the user clicks on any of these advertisements.
      </p>

      <p className="fs-5">
        While we strive to work with reputable companies with good privacy practices, this Policy does not apply to
        services offered by other companies or individuals, including products or sites that may be displayed to you on
        the Services. We also do not control the privacy policies and your privacy settings on third-party sites,
        including social networks. We may use third parties to help offer you more tailored ads and better Services,
        such as obtaining analytics about the users of our site and to help tailor advertising to your preferences. For
        further information, please see the relevant privacy policies for each third party and industry codes of
        conduct.
      </p>

      <p className="fs-5">
        We use DII to operate our Services and manage user sessions, including analyzing usage of our Services,
        preventing malicious behavioral and fraud, improving the content, to link your identity across devices and
        browsers in order to provide you with a more seamless experience online, and helping third parties provide
        relevant advertising and related metrics. We share DII with third parties primarily for advertising and
        analytics purposes, for external processing, and for security purposes.
      </p>

      <h3 className="mt-5 text-primary">Third Party Advertising Solutions Providers</h3>

      <p className="fs-5">
        We show third party advertisements (Admob, Unity Ads, etc.) within our games/apps. These ads and services are
        not under the control of CrossJump Studio who is therefore not responsible for the availability or content of
        such external sites or services. But we always apply filters in Ad networks to show ads to users as per their
        ages and content rating!
      </p>

      <h3 className="mt-5 text-primary">Information Security</h3>

      <p className="fs-5">
        We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure or
        destruction of information we hold and undertake reasonable security measures with appropriate confidentiality,
        integrity, and availability protections. However, since no software or storage system is 100% secure, we cannot
        guarantee for the security of your information associated with the Services, or any other service for that
        matter. You can help protect your account information by using unique and hard-to-guess passwords.
      </p>

      <h3 className="mt-5 text-primary">Changes to Privacy Policy</h3>

      <p className="fs-5">
        We aim to provide you with reasonable opportunity to access, update, and delete to your PII. In some cases, we
        may have to keep that information for legitimate business or legal purposes. When updating your information, we
        may ask you to verify your identity before we can act on your request.
      </p>

      <p className="fs-5">
        Our Privacy Policy may change from time to time. We will post any Policy changes on this page, including
        material changes. Please check back periodically to view changes to our privacy policy.
      </p>

      <h3 className="mt-5 text-primary">Contact</h3>

      <p className="fs-5">
        If you have questions or requests regarding our privacy practices, please contact us at{" "}
        <a href="mailto:contact@crossjump.studio">contact@crossjump.studio</a>
      </p>
    </section>
  );
};

export default PrivacyPolicy;
